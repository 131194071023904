import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/components/pages/login"),
  },
  {
    path: "/monitoring",
    name: "monitoring",
    component: () => import("@/components/pages/monitoringPage"),
    meta: {
      layout: "AppLayoutAdmin",
      auth: {
        roles: ["manager", "admin", "superadmin"],
        rolesKey: "role.name",
      },
    },
  },
  {
    path: "/hubs",
    name: "hubs",
    component: () => import("@/components/pages/hubs"),
    meta: {
      layout: "AppLayoutAdmin",
      auth: {
        roles: ["manager", "admin"],
        rolesKey: "role.name",
        notFoundRedirect: "/monitoring",
        forbiddenRedirect: { path: "/monitoring" },
      },
    },
  },
  {
    path: "/applications",
    name: "applications",
    component: () => import("@/components/pages/applications"),
    meta: {
      layout: "AppLayoutAdmin",
      auth: {
        roles: ["manager", "admin"],
        rolesKey: "role.name",
        notFoundRedirect: "/monitoring",
        forbiddenRedirect: { path: "/monitoring" },
      },
    },
  },
  {
    path: "/team",
    name: "team",
    component: () => import("@/components/pages/team"),
    meta: {
      layout: "AppLayoutAdmin",
      auth: {
        roles: ["manager", "admin"],
        rolesKey: "role.name",
        notFoundRedirect: "/monitoring",
        forbiddenRedirect: { path: "/monitoring" },
      },
    },
  },
  {
    path: "/accounts",
    name: "accounts",
    component: () => import("@/components/pages/accounts"),
    meta: {
      layout: "AppLayoutAdmin",
      auth: {
        roles: ["manager", "admin"],
        rolesKey: "role.name",
        notFoundRedirect: "/monitoring",
        forbiddenRedirect: { path: "/monitoring" },
      },
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/components/pages/settings"),
    meta: {
      layout: "AppLayoutAdmin",
      auth: {
        roles: ["manager", "admin"],
        rolesKey: "role.name",
        notFoundRedirect: "/monitoring",
        forbiddenRedirect: { path: "/monitoring" },
      },
    },
  },
  {
    path: "/status/:id",
    name: "status",
    component: () => import("@/components/pages/status"),
    props: true,
    meta: {
      layout: "AppLayoutAdmin",
      auth: {
        roles: { name: "superadmin" },
        notFoundRedirect: "/monitoring",
        forbiddenRedirect: { path: "/monitoring" },
      },
    },
  },
  {
    path: "/customers",
    name: "customers",
    component: () => import("@/components/pages/customers"),
    meta: {
      layout: "AppLayoutAdmin",
      auth: {
        roles: { name: "superadmin" },
        notFoundRedirect: "/login",
        forbiddenRedirect: { path: "/monitoring" },
      },
    },
  },
  {
    path: "*",
    redirect: "/login",
  },
];

Vue.router = new VueRouter({
  routes,
});

export default Vue.router;
