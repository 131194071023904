import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
import router from "../../router";
import { Mutex } from "async-mutex";

export const baseUrl =
  process.env.NODE_ENV !== "production"
    ? window.location.protocol + "//172.22.0.193:8080/"
    : window.location.protocol + "//" + window.location.host;

axios.defaults.baseURL = baseUrl;
Vue.use(VueAxios, axios);

const mutex = new Mutex();
let new_token = null;

axios.interceptors.request.use(
  function (config) {
    config.retry = 3;
    config.retryDelay = 1000;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

function _retry_query(originalRequest, token) {
  originalRequest.headers["Authorization"] = "Bearer " + token;
  return axios(originalRequest);
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      router.currentRoute.name !== "login" &&
      router.currentRoute.path !== "*"
    ) {
      if (error.config.url.includes("refresh")) {
        sessionStorage.clear();
        return Vue.auth.logout();
      }
      originalRequest._retry = true;
      if (mutex.isLocked()) {
        return mutex.waitForUnlock().then(() => {
          return _retry_query(originalRequest, new_token);
        });
      } else {
        return mutex
          .runExclusive(async () => {
            let tokens = await Vue.auth.refresh();
            new_token = tokens.data.data.access_token;
          })
          .then(() => {
            return _retry_query(originalRequest, new_token);
          });
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default {
  root: process.env.VUE_APP_API_URL,
};
