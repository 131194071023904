import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "@/router";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    socket: {
      isConnected: false,
      message: "",
      reconnectError: false,
    },
    auth: {},
    loading_monitoring: false,
    monitoring: [],
    hub_status: {
      badges: [],
      logs: [],
    },
    customers: [],
    hubs: [],
    team: [],
    new_team_access: [],
    edit_team_access: [],
    api_accesses: [],
    cities: [],
    all_cities: {
      all: [],
      selected: [],
      favorite: [],
      default_favorite: [],
    },
    city_check_status: {
      action: "",
      data: {},
      details: {},
      message: "",
      status: 0,
    },
    pos_check_status: {
      action: "",
      data: {},
      details: {},
      message: "",
      status: 0,
    },
    pos: [],
    new_pos: {
      name: "",
      address: "",
      city: {
        id: 0,
        name: "",
      },
    },
    edit_pos: {
      id: 0,
      name: "",
      address: "",
      city: {
        id: 0,
        name: "",
      },
    },
    original_edit_pos: {
      id: 0,
      name: "",
      address: "",
      city: {
        id: 0,
        name: "",
      },
    },
    all_poses: [],
    accounts: [],
    applications: [],
    new_account: {
      first_name: "",
      last_name: "",
      position: "",
      mail: "",
      password: "",
      add_hubs: false,
      add_users: false,
      hub_settings: false,
      application_permission: false,
      access: {
        mode: "all",
        selected: [],
      },
    },
    edit_account: {
      id: "",
      first_name: "",
      last_name: "",
      position: "",
      mail: "",
      password: "",
      add_hubs: false,
      add_users: false,
      hub_settings: false,
      application_permission: false,
      access: {
        mode: "all",
        selected: [],
      },
    },
    edit_account_access: {
      mode: "all",
      selected: [],
    },
    new_application: {
      employee_id: "",
      comment: "",
      password: "",
      pos_id: "",
      city_id: "",
      recording_mode: false,
      recording_start_time: "",
      recording_end_time: "",
      motion_detection: false,
      email: "",
    },
    edit_application: {
      employee_id: "",
      password: "",
      pos_id: "",
      city_id: "",
      comment: "",
      recording_mode: false,
      recording_start_time: "",
      recording_end_time: "",
      motion_detection: false,
      email: "",
    },
    new_customer: {},
    new_team: {},
    filters: {
      companies_owner: [],
      cities_owner: [],
      device_type_owner: [],
      pos_owner: [],
      appearance_owner: [],
      appearance_application: [],
      city: [],
      device_type: [],
      pos_name: [],
      badge_status: [],
      appearance: [],
    },
    selected_filters: {
      companies_owner: [],
      cities_owner: [],
      pos_owner: [],
      device_type_owner: [],
      appearance_owner: [
        "customer_id",
        "legal_name",
        "company",
        "city",
        "hub_id",
        "device_type",
        "pos",
        "timezone",
        "errors",
        "last_sync",
        "device_fw_ver",
      ],
      appearance_application: [],
      city: [],
      device_type: [],
      pos_name: [],
      badge_status: [],
      appearance: [
        "device_type",
        "last_name",
        "badge_id",
        "device_id",
        "uid",
        "first_name",
        "city",
        "pos_name",
        "badge_status",
        "auth_time",
      ],
    },
    edit_hub: {
      id: "",
      hub_id: "",
      local_ip: "",
      pos_id: "",
      city_id: "",
      address: "",
      hub_status: "",
      badges: "",
      timezone: "",
      fw_ver: "",
      upload_mode: "",
      motion: "",
      recording: "",
      upload_time: "",
      battery: "",
    },
  },
  actions: {
    // WS EVENTS
    update_monitoring(store, message) {
      if (router.currentRoute.name === "monitoring") {
        store.commit("UPDATE_MONITORING", message.data);
      }
      if (router.currentRoute.name === "status") {
        store.dispatch("load_hub_status", router.currentRoute.params.id);
      }
      if (router.currentRoute.name === "hubs") {
        store.commit("UPDATE_HUBS", message.data);
      }
    },
    store_auth(store) {
      axios
        .get("/auth/me")
        .then((response) => {
          const { data } = response.data;
          store.commit("STORE_AUTH", data);
        })
        .catch(() => {
          store.commit("DELETE_AUTH");
        });
    },
    delete_auth(store) {
      store.commit("DELETE_AUTH");
    },
    load_monitoring(store) {
      store.commit("LOADING_MONITORING", true);
      axios
        .get("/api/monitoring")
        .then((response) => {
          const { data } = response.data;
          store.commit("UPDATE_MONITORING", data);
        })
        .then(() => {
          store.commit("LOADING_MONITORING", false);
        })
        .finally(() => store.commit("LOADING_MONITORING", false));
    },
    quiet_load_monitoring(store) {
      axios.get("/api/monitoring").then((response) => {
        const { data } = response.data;
        store.commit("UPDATE_MONITORING", data);
      });
    },
    load_hub_status(store, hub_id) {
      axios.get(`/api/status/${hub_id}`).then((response) => {
        const { data } = response.data;
        store.commit("UPDATE_HUB_STATUS", data);
      });
    },
    delete_hub_status(store) {
      store.commit("UPDATE_HUB_STATUS", { badges: [], logs: [] });
    },
    load_customers(store) {
      axios.get("/api/customers").then((response) => {
        const { data } = response.data;
        store.commit("UPDATE_CUSTOMERS", data);
      });
    },
    add_customer(store, customer) {
      return axios
        .post("/api/customers", customer, {
          headers: { lang: localStorage.getItem("locale") },
        })
        .then(() => {
          store.dispatch("load_customers");
        });
    },
    update_customer(store, db_customer) {
      return axios.put(`/api/customers/${db_customer["id"]}`, db_customer);
    },
    remove_customer(store, db_customer) {
      return axios.delete(`/api/customers/${db_customer["id"]}`).then(() => {
        store.dispatch("load_customers");
      });
    },
    load_hubs(store) {
      axios.get("/api/hubs").then((response) => {
        const { data } = response.data;
        store.commit("UPDATE_HUBS", data);
      });
    },
    add_hub(store, hub) {
      return axios.post("/api/hub", hub).then(() => {
        store.dispatch("load_hubs");
      });
    },
    update_hub(store, hub) {
      let db_hub_id = hub["id"],
        recording = "continuous",
        upload_mode = "instant",
        data = {
          hub_id: hub["hub_id"],
          pos: hub["pos_id"].toString(),
          pos_id: hub["pos_id"].toString(),
          address: hub["address"],
          city: hub["city_id"].toString(),
          motion: hub["motion"],
          recording: recording,
          upload_mode: upload_mode,
          ready_to_use_battery: hub["battery"],
        };
      if (!hub["recording"]) {
        recording = "button";
        data["recording"] = recording;
      }
      if (!hub["upload_mode"]) {
        data["upload_mode"] = "scheduled";
        data["upload_time"] = hub["upload_time"];
      }
      return axios.put(`/api/hubs/${db_hub_id}`, data).then(() => {
        // store.dispatch("load_hubs");
      });
    },
    delete_hub(store, hub) {
      let db_hub_id = hub["id"];
      return axios.delete(`/api/hubs/${db_hub_id}`).then(() => {
        store.dispatch("load_hubs");
      });
    },
    apply_hub_params(store, params) {
      return axios.post("/api/hubs/params", params).then(() => {
        store.dispatch("load_hubs");
      });
    },
    apply_hub_upload(store, upload) {
      return axios.post("/api/hubs/upload", upload).then(() => {
        store.dispatch("load_hubs");
      });
    },
    apply_access_params(store, support) {
      return axios.post("/api/hubs/support", support).then(() => {
        store.dispatch("load_hubs");
      });
    },
    load_team(store) {
      return axios.get("/api/teams").then((response) => {
        const { data } = response.data;
        store.commit("UPDATE_TEAM", data);
        return response;
      });
    },
    update_new_team_access(store, data) {
      store.commit("UPDATE_NEW_TEAM_ACCESS", data);
    },
    update_edit_team_access(store, data) {
      store.commit("UPDATE_EDIT_TEAM_ACCESS", data);
    },
    load_cities(store, pos = false) {
      let params = "";
      if (pos) {
        params = "?fields=pos";
      }
      return axios.get("/api/city" + params).then((response) => {
        let { data } = response.data;
        store.commit("UPDATE_CITIES", data);
        return response;
      });
    },
    load_all_poses(store) {
      axios.get("api/point_of_sales").then((response) => {
        const { data } = response.data;
        store.commit("UPDATE_ALL_POSES", data);
      });
    },
    change_cities(store, data) {
      return axios.put("api/city", data).then((response) => {
        store.dispatch("load_cities");
        return response;
      });
    },
    add_city(store, data) {
      store.commit("ADD_CITY", data);
      store.dispatch("check_city_action", { data: data, action: "create" });
    },
    remove_city(store, id) {
      store.commit("REMOVE_CITY", id);
    },
    update_city_action(store, data) {
      if (data.action === "delete") {
        store.dispatch("check_city_action", { data: data, action: "delete" });
      }
      store.commit("UPDATE_CITY_ITEM_ACTION", data);
    },
    update_pos_action(store, data) {
      store.commit("UPDATE_POS_ITEM_ACTION", data);
    },
    load_all_cities(store, name, size = 50) {
      const params = [];

      if (name) {
        params.push(`name=${name}`);
      }

      if (size) {
        params.push(`size=${size}`);
      }

      return axios
        .get("/api/city/suggest?" + params.join("&"))
        .then((response) => {
          const { data } = response.data;
          store.commit("UPDATE_ALL_CITIES", data);
        });
    },
    remove_city_from_selected(store, city) {
      store.commit("REMOVE_CITY_FROM_SELECTED", city);
    },
    remove_city_from_all(store, city) {
      store.commit("REMOVE_CITY_FROM_ALL", city);
    },
    add_city_to_selected(store, city) {
      store.commit("ADD_CITY_TO_SELECTED", city);
    },
    add_city_to_all(store, city) {
      store.commit("ADD_CITY_TO_ALL", city);
    },
    add_city_to_favorites(store, city) {
      store.commit("ADD_CITY_TO_FAVORITES", city);
    },
    load_pos(store, city_id) {
      axios.get(`/api/city/${city_id}/point_of_sales`).then((response) => {
        const { data } = response.data;
        store.commit("UPDATE_POS", data);
      });
    },
    load_api_accesses(store) {
      axios.get(`/api/company/remote_api`).then((response) => {
        const { data } = response.data;
        store.commit("UPDATE_API_ACCESESS", data);
      });
    },
    revoke_api_access(store, id) {
      axios.delete(`/api/company/remote_api/${id}`).then(() => {
        store.dispatch("load_api_accesses");
      });
    },
    generate_new_key(store, id) {
      axios.put(`/api/company/remote_api/${id}`).then(() => {
        store.dispatch("load_api_accesses");
      });
    },
    add_api_access(store) {
      axios.post(`/api/company/remote_api`).then(() => {
        store.dispatch("load_api_accesses");
      });
    },
    add_employee(store, data) {
      return axios.post("/api/team", data).then(() => {
        store.dispatch("load_team");
      });
    },
    update_employee(store, data) {
      return axios.put(`/api/teams/${data.id}`, data.data);
    },
    delete_employee(store, data) {
      return axios
        .delete(`/api/teams/${data.id}`, {
          headers: {
            lang: localStorage.getItem("locale"),
          },
        })
        .then(() => {
          store.dispatch("load_team");
        });
    },
    load_accounts(store) {
      axios.get("/api/accounts").then((response) => {
        const { data } = response.data;
        store.commit("UPDATE_ACCOUNTS", data);
      });
    },
    add_account(store) {
      return axios.post("/api/account", store.state.new_account).then(() => {
        store.dispatch("load_accounts");
      });
    },
    update_account(store) {
      let account = store.state.edit_account;
      return axios.put(`/api/accounts/${account["id"]}`, account);
    },
    delete_account(store, data) {
      return axios.delete(`/api/accounts/${data["id"]}`).then(() => {
        store.dispatch("load_accounts");
      });
    },
    load_applications(store) {
      axios
        .get("/api/applications", {
          headers: { lang: localStorage.getItem("locale") },
        })
        .then((response) => {
          const { data } = response.data;
          store.commit("UPDATE_APPLICATIONS", data);
        });
    },
    add_application(store, data) {
      return axios
        .post("/api/application", data, {
          headers: { lang: localStorage.getItem("locale") },
        })
        .then(() => {
          store.dispatch("load_applications");
        });
    },
    update_application(store, data) {
      const id = data.id;
      delete data.id;
      return axios.put(`/api/applications/${id}`, data, {
        headers: { lang: localStorage.getItem("locale") },
      });
    },
    delete_application(store, data) {
      return axios
        .delete(`/api/applications/${data["id"]}`, {
          headers: { lang: localStorage.getItem("locale") },
        })
        .then(() => {
          store.dispatch("load_applications");
        });
    },
    reset_application(store) {
      store.commit("RESET_NEW_APPLICATION");
    },
    load_account_access(store, id) {
      return axios.get(`/api/accounts/${id}/access`).then((response) => {
        const { data } = response.data;
        store.commit("UPDATE_EDIT_ACCOUNT_ACCESS", data);
        return response;
      });
    },
    load_team_pos(store, id) {
      return axios.get(`/api/teams/${id}/selected_pos`).then((response) => {
        const { data } = response.data;
        store.commit("UPDATE_EDIT_TEAM_ACCESS", data);
        return response;
      });
    },
    load_team_pos_available(store, id) {
      return axios.get(`/api/teams/${id}/pos`).then((response) => {
        const { data } = response.data;
        store.commit("UPDATE_EDIT_TEAM_ACCESS", data);
        return response;
      });
    },
    load_cities_employee_id(store, payload) {
      let params = "";
      if (payload.pos) {
        params = "?fields=pos";
      }
      return axios
        .get(`/api/teams/${payload.id}/selected_pos` + params)
        .then((response) => {
          let { data } = response.data;
          store.commit("UPDATE_CITIES", data);
          return response;
        });
    },
    update_password(store, data) {
      return axios.post("/auth/me/pwd", { password: data });
    },
    update_new_account(store, account) {
      store.commit("UPDATE_NEW_ACCOUNT", account);
    },
    update_edit_account(store, account) {
      store.commit("UPDATE_EDIT_ACCOUNT", account);
    },
    update_edit_account_access(store, account_access) {
      store.commit("UPDATE_EDIT_ACCOUNT_ACCESS", account_access);
    },
    update_new_application(store, application) {
      store.commit("UPDATE_NEW_APPLICATION", application);
    },
    update_edit_application(store, application) {
      store.commit("UPDATE_EDIT_APPLICATION", application);
    },
    update_new_pos(store, pos) {
      store.commit("UPDATE_NEW_POS", pos);
    },
    update_edit_pos(store, pos) {
      store.commit("UPDATE_EDIT_POS", pos);
    },
    add_pos(store, pos) {
      store.commit("ADD_POS", pos);
    },
    remove_pos(store, pos) {
      store.commit("REMOVE_POS", pos);
    },
    restore_filters(store) {
      store.commit("RESTORE_FILTERS");
    },
    set_filters(store, filter) {
      store.commit("SET_FILTERS", filter);
    },
    apply_appearance_owner_filters(store) {
      store.commit("APPLY_APPEARANCE_OWNER_FILTERS");
    },
    apply_appearance_filters(store) {
      store.commit("APPLY_APPEARANCE_FILTERS");
    },
    apply_appearance_application_filters(store) {
      store.commit("APPLY_APPLICATION_APPEARANCE_FILTERS");
    },
    select_filter(store, selected_filter) {
      store.commit("SELECT_FILTER", selected_filter);
    },
    select_all_appearance_filters(store, filters) {
      store.commit("SELECT_ALL_APPEARANCE_FILTERS", filters);
    },
    clear_filters(store) {
      store.commit("CLEAR_FILTERS");
    },
    clear_all_filters(store) {
      store.commit("CLEAR_ALL_FILTERS");
    },
    apply_filters(store) {
      store.commit("APPLY_FILTERS");
    },
    edit_hub(store, hub) {
      store.commit("EDIT_HUB", hub);
    },
    edit_pos_in_collection(store, pos) {
      store.commit("EDIT_POS_IN_COLLECTION", pos);
    },
    save_original_edit_state(store, pos) {
      store.commit("SAVE_ORIGINAL_EDIT_STATE", pos);
    },
    restore_original_edit_state(store) {
      store.commit("RESTORE_ORIGINAL_EDIT_STATE");
    },
    add_poses(store, data) {
      return axios.put("/api/point_of_sales", data).then((response) => {
        store.dispatch("load_all_poses");
        return response;
      });
    },
    check_city_action(store, payload) {
      const { data, action } = payload;
      const defaultStructure = {
        action: action,
        data: {
          id: data.value,
          name: data.name,
          global_id: data.uuid,
        },
      };
      return axios
        .post("/api/city/check", defaultStructure, {
          headers: { lang: localStorage.getItem("locale") },
        })
        .then((response) => {
          const { data } = response.data;
          store.commit("UPDATE_CITY_CHECK_STATUS", data);
        });
    },
    check_pos_action(store, payload) {
      const { data, action } = payload;
      const defaultStructure = {
        action: action,
        data: {
          id: data.id,
          name: data.name,
          address: data.address,
          city: {
            id: data.city_id ?? data.city.id,
          },
        },
      };
      return axios
        .post("/api/point_of_sales/check", defaultStructure, {
          headers: { lang: localStorage.getItem("locale") },
        })
        .then((response) => {
          const { data } = response.data;
          store.commit("UPDATE_POS_CHECK_STATUS", data);
          return response;
        });
    },
    clear_city_check_action(store) {
      store.commit("CLEAR_CITY_CHECK_ACTION");
    },
    clear_pos_check_action(store) {
      store.commit("CLEAR_POS_CHECK_ACTION");
    },
  },
  mutations: {
    UPDATE_NEW_TEAM_ACCESS(state, payload) {
      state.new_team_access = payload;
    },
    UPDATE_EDIT_TEAM_ACCESS(state, payload) {
      state.edit_team_access = payload;
    },
    CLEAR_CITY_CHECK_ACTION(state) {
      state.city_check_status = {
        action: "",
        data: {},
        details: {},
        message: "",
        status: 0,
      };
    },
    CLEAR_POS_CHECK_ACTION(state) {
      state.pos_check_status = {
        action: "",
        data: {},
        details: {},
        message: "",
        status: 0,
      };
    },
    UPDATE_POS_CHECK_STATUS(state, payload) {
      if (payload.status !== 200) {
        let itemIndex = state.all_poses.findIndex(
          (x) => x.id === payload.data.id
        );
        state.all_poses[itemIndex].error = true;
        state.all_poses.push();
      }
      state.pos_check_status = payload;
    },
    UPDATE_CITY_CHECK_STATUS(state, payload) {
      if (payload.status !== 200) {
        let itemIndex = state.cities.findIndex((x) => x.id === payload.data.id);
        state.cities[itemIndex].error = true;
        state.cities.push();
      }
      state.city_check_status = payload;
    },
    RESTORE_ORIGINAL_EDIT_STATE(state) {
      const index = state.all_poses.findIndex(
        (x) => x.id === state.original_edit_pos.id
      );
      state.all_poses[index].name = state.original_edit_pos.name;
      state.all_poses[index].city.id = state.original_edit_pos.city_id;
      state.all_poses[index].city.name = state.original_edit_pos.city_name;
      state.all_poses[index].error = false;
      state.all_poses.push();
    },
    SAVE_ORIGINAL_EDIT_STATE(state, payload) {
      if (payload.action !== "create") {
        state.original_edit_pos = payload;
      }
    },
    SOCKET_ONOPEN(state, event) {
      Vue.prototype.$socket = event.currentTarget;
      state.socket.isConnected = true;
    },
    SOCKET_ONCLOSE(state) {
      state.socket.isConnected = false;
    },
    SOCKET_ONERROR() {},
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
      state.socket.message = message;
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT() {},
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    },
    STORE_AUTH(state, payload) {
      state.auth = payload;
    },
    DELETE_AUTH(state) {
      state.auth = null;
    },
    UPDATE_MONITORING(state, payload) {
      state.monitoring = payload;
    },
    UPDATE_HUB_STATUS(state, payload) {
      state.hub_status = payload;
    },
    UPDATE_CUSTOMERS(state, payload) {
      state.customers = payload;
    },
    UPDATE_HUBS(state, payload) {
      state.hubs = payload;
    },
    UPDATE_TEAM(state, payload) {
      state.team = payload;
    },
    UPDATE_API_ACCESSES(state, payload) {
      state.api_accesses = payload;
    },
    UPDATE_POS(state, payload) {
      state.pos = payload;
    },
    UPDATE_ALL_POSES(state, payload) {
      state.all_poses = payload;
    },
    ADD_POS(state, payload) {
      state.all_poses.push(payload);
    },
    REMOVE_POS(state, payload) {
      if (payload?.action === "create") {
        return (state.all_poses = state.all_poses.filter(
          (x) => x.id !== payload.id
        ));
      }
      if (payload.action === "delete") {
        delete state.all_poses.find((x) => x.id === payload.id).action;
      }
      if (!payload.action || payload.action === "update") {
        state.all_poses.find((x) => x.id === payload.id).action = "delete";
      }
      state.all_poses.find((x) => x.id === payload.id).error = false;
      state.all_poses.push();
    },
    EDIT_POS_IN_COLLECTION(state, payload) {
      const currentIndex = state.all_poses.findIndex(
        (x) => x.id === payload.id
      );
      state.all_poses[currentIndex].name = payload.name;
      state.all_poses[currentIndex].city.id = payload.city.id;
      state.all_poses[currentIndex].city.name = payload.city.name;
      if (!payload.action) {
        state.all_poses[currentIndex].action = "update";
      }
    },
    UPDATE_CITIES(state, payload) {
      state.cities = payload;
    },
    ADD_CITY(state, payload) {
      const defaultStructure = {
        action: "create",
        favorite: payload.favorite,
        global_id: payload.uuid,
        id: payload.value,
        name: payload.name,
        region: payload.region,
        area: payload.area,
      };
      state.cities.push(defaultStructure);
    },
    REMOVE_CITY(state, payload) {
      state.cities = state.cities.filter((x) => x?.id !== payload.value);
    },
    UPDATE_CITY_ITEM_ACTION(state, payload) {
      let itemIndex = state.cities.findIndex((x) => x.id === payload.value);
      state.cities[itemIndex].action = payload.action;
      state.cities[itemIndex].checked = payload.checked;
      state.cities[itemIndex].error = payload.error;
      state.cities.push();
    },
    UPDATE_POS_ITEM_ACTION(state, payload) {
      let itemIndex = state.all_poses.findIndex((x) => x.id === payload.value);
      state.all_poses[itemIndex].action = payload.action;
      state.all_poses.push();
    },
    UPDATE_ALL_CITIES(state, payload) {
      state.all_cities = payload;
      state.all_cities.default_favorite = payload.favorite;
    },
    REMOVE_CITY_FROM_SELECTED(state, payload) {
      state.all_cities.selected = state.all_cities.selected.filter(
        (x) => x.id !== payload.value
      );
      state.all_cities.selected.push();
    },
    REMOVE_CITY_FROM_ALL(state, payload) {
      state.all_cities.all = state.all_cities.all.filter(
        (x) => x.id !== payload.value
      );
    },
    ADD_CITY_TO_SELECTED(state, payload) {
      const defaultStructure = {
        id: payload.value,
        favorite: payload.favorite,
        global_id: Math.floor(Math.random() * 20000),
        name: payload.name,
        area: payload.area,
        region: payload.region,
      };
      state.all_cities.selected.push(defaultStructure);
      state.all_cities.all = state.all_cities.all.filter(
        (x) => x.id !== payload.value
      );
      state.all_cities.favorite = state.all_cities.favorite.filter(
        (x) => x.id !== payload.value
      );
    },
    ADD_CITY_TO_ALL(state, payload) {
      const defaultStructure = {
        id: payload.value,
        global_id: payload.uuid,
        name: payload.name,
        area: payload.area,
        region: payload.region,
      };
      state.all_cities.all.push(defaultStructure);
    },
    ADD_CITY_TO_FAVORITES(state, payload) {
      const defaultStructure = {
        id: payload.value,
        name: payload.name,
        area: payload.area,
        region: payload.region,
      };
      state.all_cities.favorite.push(defaultStructure);
    },
    UPDATE_ACCOUNTS(state, payload) {
      state.accounts = payload;
    },
    UPDATE_APPLICATIONS(state, payload) {
      state.applications = payload;
    },
    LOADING_MONITORING(state, payload) {
      state.loading_monitoring = payload;
    },
    UPDATE_NEW_ACCOUNT(state, account) {
      state.new_account = account;
    },
    UPDATE_EDIT_ACCOUNT(state, account) {
      state.edit_account = account;
    },
    UPDATE_EDIT_ACCOUNT_ACCESS(state, account_access) {
      state.edit_account_access = account_access;
    },
    UPDATE_NEW_APPLICATION(state, application) {
      state.new_application = application;
    },
    RESET_NEW_APPLICATION(state) {
      state.new_application = {
        employee_id: "",
        password: "",
        pos_id: "",
        city_id: "",
        pos_address: "",
        recording_mode: false,
        recording_start_time: "",
        recording_end_time: "",
        motion_detection: false,
        email: "",
      };
    },
    UPDATE_EDIT_APPLICATION(state, application) {
      state.edit_application = application;
    },
    UPDATE_NEW_POS(state, pos) {
      state.new_pos = pos;
    },
    UPDATE_EDIT_POS(state, pos) {
      state.edit_pos = pos;
    },
    SET_FILTERS(state, filter) {
      state.filters[filter.name] = filter.value;
    },
    APPLY_APPEARANCE_OWNER_FILTERS(state) {
      state.filters.appearance_owner = [
        ...state.selected_filters.appearance_owner,
      ];
    },
    APPLY_APPEARANCE_FILTERS(state) {
      state.filters.appearance = [...state.selected_filters.appearance];
      for (const key of Object.keys(state.selected_filters)) {
        if (key.includes("appearance")) {
          state.filters[key] = [...state.selected_filters[key]];
        }
      }
      for (const [key, value] of Object.entries(state.filters)) {
        if (key.includes("appearance")) {
          sessionStorage.setItem(`${key}_list`, JSON.stringify(value));
        }
      }
    },
    APPLY_APPLICATION_APPEARANCE_FILTERS(state) {
      state.filters.appearance_application = [
        ...state.selected_filters.appearance_application,
      ];
    },
    SELECT_FILTER(state, filter) {
      if (state.selected_filters[filter.name].includes(filter.value)) {
        const index = state.selected_filters[filter.name].indexOf(filter.value);
        if (index > -1) {
          state.selected_filters[filter.name].splice(index, 1);
        }
      } else {
        state.selected_filters[filter.name].push(filter.value);
      }
    },
    SELECT_ALL_APPEARANCE_FILTERS(state, data) {
      const { name, filters } = data;
      state.selected_filters[name] = filters;
    },
    CLEAR_FILTERS(state) {
      for (const key of Object.keys(state.filters)) {
        if (
          !key.includes("appearance") &&
          !key.includes("appearance_owner") &&
          !key.includes("appearance_application")
        ) {
          state.filters[key] = [...[]];
          state.selected_filters[key] = [...[]];
          sessionStorage.removeItem(`${key}_list`);
        }
      }
      state.monitoring = [];
      state.hubs = [];
      state.team = [];
      state.accounts = [];
      state.hub_status = {
        badges: [],
        logs: [],
      };
    },
    CLEAR_ALL_FILTERS(state) {
      for (const key of Object.keys(state.filters)) {
        state.filters[key] = [...[]];
        state.selected_filters[key] = [...[]];
        sessionStorage.removeItem(`${key}_list`);
      }
      state.selected_filters.appearance = [
        "device_type",
        "last_name",
        "badge_id",
        "device_id",
        "uid",
        "first_name",
        "city",
        "pos_name",
        "badge_status",
        "auth_time",
      ];
      state.selected_filters.appearance_owner = [
        "customer_id",
        "legal_name",
        "company",
        "city",
        "hub_id",
        "device_type",
        "pos",
        "timezone",
        "errors",
        "last_sync",
        "device_fw_ver",
      ];
      state.monitoring = [];
      state.hubs = [];
      state.team = [];
      state.accounts = [];
      state.hub_status = {
        badges: [],
        logs: [],
      };
    },
    APPLY_FILTERS(state) {
      for (const key of Object.keys(state.selected_filters)) {
        state.filters[key] = [...state.selected_filters[key]];
      }
      for (const [key, value] of Object.entries(state.filters)) {
        sessionStorage.setItem(`${key}_list`, JSON.stringify(value));
      }
    },
    RESTORE_FILTERS(state) {
      for (const [key, value] of Object.entries(state.filters)) {
        if (sessionStorage.getItem(`${key}_list`)) {
          let loaded_filters = JSON.parse(
            sessionStorage.getItem(`${key}_list`)
          );
          if (loaded_filters !== value) {
            state.filters[key] = [...loaded_filters];
            state.selected_filters[key] = [...loaded_filters];
          }
        }
      }
    },
    EDIT_HUB(state, hub) {
      state.edit_hub = hub;
    },
  },
  getters: {
    NEW_TEAM_ACCESS(state) {
      return state.new_team_access;
    },
    EDIT_TEAM_ACCESS(state) {
      return state.edit_team_access;
    },
    CITY_CHECK_STATUS(state) {
      return state.city_check_status;
    },
    POS_CHECK_STATUS(state) {
      return state.pos_check_status;
    },
    MONITORING(state) {
      return state.monitoring;
    },
    HUB_STATUS(state) {
      return state.hub_status;
    },
    BADGES_STATUS(state) {
      return state.hub_status.badges;
    },
    HUB_LOGS(state) {
      return state.hub_status.logs;
    },
    CUSTOMERS(state) {
      return state.customers;
    },
    API_ACCESSES(state) {
      return state.api_accesses;
    },
    HUBS(state) {
      return state.hubs;
    },
    TEAM(state) {
      return state.team;
    },
    CITIES(state) {
      return state.cities;
    },
    ALL_CITIES(state) {
      return state.all_cities.all;
    },
    SELECTED_CITIES(state) {
      return state.all_cities.selected;
    },
    FAVORITE_CITIES(state) {
      return state.all_cities.favorite;
    },
    NEW_POS(state) {
      return state.new_pos;
    },
    EDIT_POS(state) {
      return state.edit_pos;
    },
    POS(state) {
      return state.pos;
    },
    ALL_POSES(state) {
      return state.all_poses;
    },
    ACCOUNTS(state) {
      return state.accounts;
    },
    APPLICATIONS(state) {
      return state.applications;
    },
    LOADING_MONITORING(state) {
      return state.loading_monitoring;
    },
    NEW_ACCOUNT(state) {
      return state.new_account;
    },
    EDIT_ACCOUNT(state) {
      return state.edit_account;
    },
    EDIT_ACCOUNT_ACCESS(state) {
      return state.edit_account_access;
    },
    NEW_APPLICATION(state) {
      return state.new_application;
    },
    EDIT_APPLICATION(state) {
      return state.edit_application;
    },
    FILTERED_COMPANIES(state) {
      return state.monitoring;
    },
    FILTERED_CITIES(state, getters) {
      let hubs = getters.FILTERED_COMPANIES;
      if (state.selected_filters.companies_owner.length > 0) {
        hubs = hubs.filter((hub) =>
          state.selected_filters.companies_owner.includes(
            hub.company.legal_name
          )
        );
      }
      return hubs;
    },
    FILTERED_POSES(state, getters) {
      let hubs = getters.FILTERED_CITIES;
      if (state.selected_filters.cities_owner.length > 0) {
        hubs = hubs.filter((hub) =>
          state.selected_filters.cities_owner.includes(hub.city)
        );
      }
      return hubs;
    },
    FILTERED_CITY(state) {
      return state.monitoring;
    },
    FILTERED_DEVICE(state) {
      return state.monitoring;
    },
    FILTERED_POS(state, getters) {
      let badges = getters.FILTERED_CITY;
      if (state.selected_filters.city.length > 0) {
        badges = badges.filter((badge) =>
          state.selected_filters.city.includes(badge.city)
        );
      }
      return badges;
    },
    FILTERS(state) {
      return state.filters;
    },
    FILTERED_HUBS(state) {
      let hubs = state.monitoring;
      if (state.filters.companies_owner.length > 0) {
        hubs = hubs.filter((hub) =>
          state.filters.companies_owner.includes(hub.company.legal_name)
        );
      }
      if (state.filters.cities_owner.length > 0) {
        hubs = hubs.filter((hub) =>
          state.filters.cities_owner.includes(hub.city)
        );
      }
      if (state.filters.device_type_owner.length > 0) {
        hubs = hubs.filter((hub) =>
          state.filters.device_type_owner.includes(hub.device_type)
        );
      }
      if (state.filters.pos_owner.length > 0) {
        hubs = hubs.filter((hub) =>
          state.filters.pos_owner.includes(hub.pos_name)
        );
      }
      return hubs;
    },
    FILTERED_BADGES(state) {
      let badges = state.monitoring;
      if (state.filters.city.length > 0) {
        badges = badges.filter((badge) =>
          state.filters.city.includes(badge.city)
        );
      }
      if (state.filters.pos_name.length > 0) {
        badges = badges.filter((badge) =>
          state.filters.pos_name.includes(badge.pos_name)
        );
      }
      if (state.filters.device_type.length > 0) {
        badges = badges.filter((badge) =>
          state.filters.device_type.includes(badge.device_type)
        );
      }
      if (state.filters.badge_status.length > 0) {
        badges = badges.filter((badge) => {
          if (badge.badge_status == null) {
            return false;
          }
          return new RegExp(state.filters.badge_status.join("|")).test(
            badge.badge_status
          );
        });
      }
      return badges;
    },
    SELECTED_FILTERS(state) {
      return state.selected_filters;
    },
    EDIT_HUB(state) {
      return state.edit_hub;
    },
  },
});
