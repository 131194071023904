export default {
  nav: {
    login: "Login as admin",
    logout: "Log out",
    notion_link:
      "https://speechmate.yonote.ru/share/3fc951ae-b504-48c6-bd16-07729d4612e4",
    monitoring: "Monitoring",
    hubs: "Hubs",
    team: "Team",
    accounts: "Accounts",
    settings: "Settings",
    monitoring_owner: "Monitoring",
    customers: "Customers",
    support: "Support",
    applications: "Applications",
  },
  general: {
    placeholder: "Choose an option",
    time_hh: "hh",
    time_mm: "mm",
  },
  login: {
    login_placeholder: "E-mail",
    password_placeholder: "Password",
    login: "Log in",
    error_text: "Email or password incorrect",
  },
  monitoring: {
    city: "City",
    pos: "PoS",
    badge_status: "Badge status",
    device_type: "Device type",
    apply_filters: "Apply filters",
    clear_filters: "Clear filters",
    appearance: "Appearance",
    not_specified: "Not specified",
    headers: {
      city: "City",
      pos_name: "PoS",
      device_type: "Device type",
      device_id: "Device ID",
      device_status: "Device status",
      badge_id: "Badge ID",
      uid: "UID",
      last_name: "Last name",
      first_name: "First name",
      auth_time: "Auth Time",
      n_a_time: "N/A time",
      active_time: "Active time",
      total_time: "Total time",
      badge_status: "Badge Status",
      fw_ver: "Fw. ver",
    },
    btn: {
      apply: "Apply",
      select_all: "Select all",
    },
  },
  hubs: {
    hub_id: "Hub ID",
    hub_id_hint: "Required",
    pos: "PoS",
    city: "City",
    address: "Address",
    add_btn: "Add hub",
    choose_city: "Chosse city from the list",
    choose_pos: "Choose pos from the list",
    empty_city: "No available cities",
    empty_cities: "You need to add cities of presence in the",
    settings: "Settings section",
    loader: {
      progress: "Adding in progress. Do not leave the tab",
      progress_edit: "Editing in progress. Do not leave the tab",
      failed: "Failed to add hub",
      failed_edit: "Failed to edit hub",
      no_mac: "MAC address verification failed",
      success: "The device has been successfully added",
      success_edit: "The device has been successfully edited",
    },
    headers: {
      hub_id: "Hub ID",
      local_ip: "Local IP",
      pos: "PoS",
      city: "City",
      address: "Address",
      badges: "Badges",
      hub_status: "Hub status",
      timezone: "Timezone",
      fw_ver: "FW ver.",
    },
    confirm_text: "You are going to delete a hub. Are you sure?",
    confirm_button: "Delete",
    confirm_add_text:
      "All employees on the hub will be overwritten by employees from the cloud",
    confirm_add_button: "Overwrite",
    change_block: {
      recording_mode: "Recording mode",
      continuous_recording: "Continuous recording",
      with_button_recording: "Recording with a button",
      upload_mode: "Upload mode",
      immediate_upload: "Immediate upload",
      postponed_upload: "Postponed upload in (local time)",
      motion_detection: "Motion Detection",
      on: "On",
      off: "Off",
      battery_level: '"Ready to use" battery level',
      update_msg: "Settings updated",
      update_msg_error: "Error. Settings were not updated",
      hints: {
        upload: "Input local time for scheduling data upload to the server.",
        motion:
          "When the feature is ON, the badge will automatically stop recording if no motion is detected and automatically resume recording when motion occurs",
      },
      save: "Save",
      cancel: "Cancel",
      delete: "Delete",
    },
  },
  applications: {
    comment: "Comment",
    username: "User name",
    password: "Password",
    pos: "PoS",
    city: "City",
    address: "Address",
    email: "Email",
    recording_mode: "Recording mode",
    recording_start: "Start recording",
    recording_end: "End recording",
    motion_detection: "Motion detection",
    generate: "Generate",
    create: "Add",
    on: "On",
    off: "Off",
    on_timer: "On timer",
    on_button_press: "On button press",
    no_content: "You haven't set up access to the mobile app yet",
    loader: {
      failed: "Failed to add application",
      failed_edit: "Failed to edit application",
      success: "The application has been successfully added",
      progress: "Adding in progress. Do not leave the tab",
      progress_edit: "Saving in progress. Do not leave the tab",
      success_edit: "The application has been successfully edited",
    },
    headers: {
      comment: "Comments",
      app_id: "APP ID",
      employee: "Name",
      pos_name: "PoS",
      city: "City",
      pos_address: "Address",
      recording_mode: "Recording mode",
      recording_mode_text: "Recording mode",
      status: "Status",
      badge_id: "Badge ID",
      fw_version: "FW ver.",
    },
    confirm_text:
      "You are going to revoke access to the current mobile application. Are you sure?",
    confirm_button: "Revoke",
    cancel_button: "Cancel",
    change_block: {
      save: "Save",
      cancel: "Cancel",
      delete: "Delete",
    },
    errors: {
      mail_exist: "The email address is already in use by the employee: ",
      no_password: "Password does not match requirements",
      no_mail: "Incorrect email format",
    },
  },
  team: {
    first_name: "First name",
    last_name: "Last name",
    uid: "User ID",
    text_field_hint: "Required",
    generate: "Generate",
    add_user: "Add user",
    all_cities: "All cities",
    all_poses: "All PoSes",
    not_selected_city: "City is not selected",
    not_selected_pos: "PoS is not selected",
    empty_city: "No available cities",
    empty_pos: "No available poses",
    city: "City",
    pos: "PoS",
    empty_cities: "You need to add cities of presence in the",
    settings: "Settings section",
    endings_city: {
      multiple_a: "cities",
      multiple_b: "cities",
      single: "city",
    },
    endings_pos: {
      multiple_a: "PoSes",
      multiple_b: "PoSes",
      single: "PoS",
    },
    loader: {
      progress: "Adding in progress. Do not leave the tab",
      progress_edit: "Editing in progress. Do not leave the tab",
      failed: "Failed to add user",
      failed_edit: "Failed to edit user",
      employee_id_exist: "UID already exists in database",
      success: "The user has been successfully added",
      success_edit: "The user has been successfully edited",
      no_auth:
        "Selected user is currently authorized in the system and cannot be deleted",
    },
    headers: {
      first_name: "First name",
      last_name: "Last name",
      employee_id: "User ID",
      authorized: "Authorized",
    },
    change_block: {
      save: "Save",
      cancel: "Cancel",
      delete: "Delete",
    },
    confirm_text: "You are going to delete a user. Are you sure?",
    auth_error:
      "Selected user is currently authorized in the system and cannot be deleted",
    close_button: "Close",
    confirm_button: "Delete",
  },
  accounts: {
    first_name: "First name",
    last_name: "Last name",
    position: "Position",
    mail: "E-mail",
    password: "Password",
    generate: "Generate",
    text_field_hint: "Required",
    add_hubs: "Add hubs",
    add_users: "Add users",
    application_permission: "Application permission",
    change_settings: "Change Hub settings",
    yes: "Yes",
    no: "No",
    access_mode: "Access mode",
    all: "All",
    selected_only: "Selected only",
    create: "Create account",
    all_cities: "All cities",
    all_poses: "All PoSes",
    not_selected_city: "City is not selected",
    not_selected_pos: "PoS is not selected",
    empty_city: "No available cities",
    empty_pos: "No available poses",
    city: "City",
    pos: "PoS",
    empty_cities: "You need to add cities of presence in the",
    settings: "Settings section",
    endings_city: {
      multiple_a: "cities",
      multiple_b: "cities",
      single: "city",
    },
    endings_pos: {
      multiple_a: "PoSes",
      multiple_b: "PoSes",
      single: "PoS",
    },
    loader: {
      progress: "Creation in progress. Please wait”",
      progress_edit: "Editing in progress. Please wait”",
      failed: "Failed to add account",
      failed_edit: "Failed to edit account",
      success: "New account has been successfully added",
      success_edit: "The account has been successfully edited",
    },
    headers: {
      account_id: "ID",
      first_name: "First name",
      last_name: "Last name",
      position: "Position",
      mail: "E-mail",
      date: "Creation date",
    },
    confirm_text:
      "ATTENTION! You are going to delete manager’s account. Are you sure?",
    confirm_button: "Delete",
    change_block: {
      save: "Save",
      cancel: "Cancel",
      delete: "Delete",
    },
  },
  settings: {
    settings: "Settings",
    password: {
      change: "Change password",
      new_password: "New password",
      confirm_password: "Confirm new password",
      policy_title: "Password policy",
      policy: [
        "The password length must be greater than or equal to 8",
        "The password must contain one or more uppercase characters",
        "The password must contain one or more lowercase characters",
        "The password must contain one or more numeric values",
        "The password must contain one or more special characters",
      ],
      loader: {
        progress: "Editing password. Please wait",
        failed: "Failed to edit password",
        success: "The password has been successfully edited",
        confirm: "Confirm the password",
        wrong_password: "Wrong password",
        no_requirements: "Password doesn’t comply with the requirements",
      },
      apply: "Apply",
    },
    hub: {
      title: "Hub settings",
      settings: {
        recording_mode: "Recording mode",
        continuous_recording: "Continuous recording",
        with_button_recording: "Recording with a button",
        upload_mode: "Upload mode",
        immediate_upload: "Immediate upload",
        postponed_upload: "Postponed upload in (local time)",
        motion_detection: "Motion Detection",
        on: "On",
        off: "Off",
        battery_level: '"Ready to use" battery level',
        update_msg: "Settings updated",
        update_msg_error: "Error. Settings were not updated",
        hints: {
          upload: "Input local time for scheduling data upload to the server.",
          motion:
            "When the feature is ON, the badge will automatically stop recording if no motion is detected and automatically resume recording when motion occurs",
        },
      },
      loader: {
        progress: "Editing settings. Please wait",
        failed: "Failed edit settings",
        success: "Settings has been successfully edited",
      },
      apply: "Apply all",
    },
    upload: {
      title: "Data upload",
      mode: "Mode",
      token: "Token",
      key: "Access key ID",
      secret_key: "Secret Access Key",
      api_key: "API key",
      username: "Username",
      data_processing_enable: "Server audio processing",
      dialog_frame_spotting: "Dialog frame by Key Word Spotting",
      upload_volume: "Upload volume",
      upload_volume_options: {
        all: "Full",
        optimal: "Part",
        minimal: "Minimal",
      },
      bucket_name: "Bucket name",
      region: "Region",
      host: "Host",
      port: "Port",
      user: "User",
      on: "On",
      off: "Off",
      password: "Password",
      save: "Save",
      hint: {
        data_processing_enable:
          "Enabling the audio server processing (source audio files separation with VAD and/or KWS)",
        dialog_frame_spotting:
          "Dividing audio files into dialogues based on the greeting words and goodbye words.",
        upload_volume:
          "<b>Full access</b> - all the files uploads to the Speech Analytics." +
          "<br><b>Part</b> - Only Dialog Frames uploads to the Speech Analytics." +
          "<br><b>Minimal</b> - Only Dialog Frames uploads to the Speech Analytics. Rest - deletes",
      },
      loader: {
        progress: "Editing settings. Please wait",
        failed: "Failed edit settings",
        success: "Settings has been successfully edited",
      },
      apply: "Apply all",
    },
    accesses: {
      title: "Speechmate accesses",
      audio_access: "Grant access to audio to Speechmate team?",
      support: "Enable advanced technical support?",
      warning:
        "Warning! If you grant access you will have backup files and support the development of the Speechmate service.",
      yes: "Yes",
      no: "No",
      loader: {
        progress: "Editing settings. Please wait",
        failed: "Failed edit settings",
        success: "Settings has been successfully edited",
      },
    },
    api: {
      title: "Api Access",
      add_access: "Add access",
      public_key: "Public key",
      private_key: "Private key",
      remove_access: "Remove access",
      generate: "Generate new key",
      no_content: "No accesses",
      add_confirm: {
        text: "Acces granted. Please save your private key before closing this window:",
        confirm: "Close",
      },
      remove_confirm: {
        text: "Are you sure you want to revoke access?",
        confirm: "Revoke",
        cancel: "Cancel",
      },
      headers: {
        public_key: "Public key",
        client_id: "ID",
      },
    },
    city: {
      title: "City of pressence",
      city: "City",
      create: "created",
      delete: "deleted",
      count_end: "cities",
      warning: "You are going to",
      delete_count: "delete",
      create_count: "add",
      not: "was not",
      is: "was",
      and: "and",
      apply: "Apply",
      text_field_hint: "All cities",
      check: {
        general: "Attention! To delete this city you must delete connected",
        hubs: `hubs`,
        applications: `applications`,
        employees: `employees`,
        accounts: `accounts`,
        poses: `points of sales`,
        ending: "or apply according changes.",
      },
      endings: {
        multiple_a: "cities",
        multiple_b: "cities",
        single: "city",
      },
    },
    pos: {
      title: "Point of sales management",
      pos: "Point of sales",
      city: "City",
      add: "Add point",
      apply: "Apply",
      new_pos: "Enter name",
      delete_count: "delete",
      create_count: "add",
      warning: "You are going to",
      not: "was not",
      is: "was",
      and: "and",
      check: {
        general:
          "Attention! To delete this point of sales you must delete connected",
        hubs: `hubs`,
        applications: `applications`,
        employees: `employees`,
        accounts: `accounts`,
        poses: `points of sales`,
        ending: "or apply according changes.",
      },
      endings: {
        multiple_a: "points of sales",
        multiple_b: "points of sales",
        single: "point of sales",
      },
      headers: {
        name: "PoS name",
        city_name: "City",
      },
    },
  },
  monitoring_owner: {
    company: "Company",
    city: "City",
    pos: "PoS",
    apply_filters: "Apply filters",
    clear_filters: "Clear filters",
    appearance: "Appearance",
    headers: {
      comment: "Comment",
      customer_id: "Customer ID",
      legal_name: "Legal name",
      company: "Company",
      city: "City",
      hub_id: "Hub ID",
      device_type: "Device type",
      pos: "PoS",
      address: "Address",
      timezone: "Timezone",
      errors: "Errors",
      last_sync: "Last update",
      device_fw_ver: "Fw. ver",
      status: "Status",
    },
  },
  status: {
    log: "Events log",
    log_download: "Download log",
    info: {
      customer: "Customer",
      pos: "PoS",
      address: "Address",
      comment: "Comment",
      timezone: "Timezone",
      hub_id: "Hub ID",
      battery_level: '"Ready to use" battery level',
      upload_mode: "Upload mode",
      recording_mode: "Recording mode",
      motion: "Motion detection",
      fw_ver: "FW ver.",
      yes: "Yes",
      no: "No",
      postponed: "Postponed upload in ",
      immediate: "Immediate upload",
      continuous_recording: "Continuous recording",
      with_button_recording: "Recording with a button",
    },
    headers: {
      badge_id: "Badge ID",
      uid: "UID",
      auth_time: "Auth Time",
      n_a_time: "N/A time",
      active_time: "Active time",
      total_time: "Total time",
      badge_status: "Badge Status",
      fw_ver: "Fw. ver",
      date: "Date",
      description: "Description",
    },
  },
  customers: {
    customer_id: "Customer ID",
    password: "Password",
    legal_name: "Legal name",
    company: "Company",
    admin_name: "Admin name",
    mail: "E-mail",
    phone: "Phone",
    add_customer: "Add customer",
    text_field_hint: "Required",
    loader: {
      progress: "Adding in progress. Do not leave the tab",
      progress_edit: "Editing in progress. Do not leave the tab",
      failed: "Failed to add customer",
      failed_edit: "Failed to edit customer",
      id_exist: "ID already exists in database",
      mail_exist: "E-Mail already exists in database",
      success: "New customer has been successfully added",
      success_edit: "The customer has been successfully edited",
    },
    errors: {
      no_phone: "Please check phone number.",
      no_password: "Please check password",
      no_mail: "Incorrect email format",
      value: "An error in the data. Please check the completed information",
    },
    headers: {
      customer_id: "Customer ID",
      legal_name: "Legal name",
      company: "Company",
      admin_name: "Admin name",
      mail: "E-mail",
      phone: "Phone",
    },
    generate: "Generate",
    confirm_text:
      "ATTENTION! You are going to delete customer’s account. Are you sure?",
    confirm_button: "Delete",
    change_block: {
      save: "Save",
      cancel: "Cancel",
      delete: "Delete",
    },
  },
  components: {
    battery_level: {
      title: '"Ready to use" battery level',
      hint: "Enter the minimal badge battery level sufficient for its usage",
    },
    table_btn: {
      edit: "Edit",
      delete: "Delete",
    },
  },
  errors: {
    required: "Fill in the mandatory field",
    invalid_range: "Invalid range",
    format: "Invalid format",
    no_letters: "First or last name shall contain at least one letter",
    no_password: "Password does not match requirements",
    numbers_and_letters_only: "Numbers and letters only",
    numbers_letters_symbols:
      "User ID shall contain letters, numbers and symbols №#.,/-_:;",
    value: "Incorrect value",
  },
  log: {
    copy_error: "Audio file transmitting error ",
    decrypt_error: "Audio file decryption error",
    upload_error: "Audio file upload error",
    usb_error: "USB device recognition error ",
    block_error: "Badge partition error",
    hub_added: "Hub added",
    hub_deleted: "Hub deleted",
    hub_offline: "Hub is offline",
    hub_online: "Hub is online",
    hub_settings_cloud: "Updated hub settings on cloud",
    hub_settings_hub: "Updated hub settings on hub",
    unknown_error: "Unknown error",
    meta_upload_error: "Metadata upload error",
  },
  badge: {
    status: {
      recording: "Recording",
      on_body: "On body",
      waiting: "Waiting",
      rebooted: "Rebooted",
      syncing: "Syncing",
      sync_complete: "Sync complete",
      sync_error: "Sync error",
      charging: "Charging",
      not_in_range: "Not in range",
      connecting: "Connecting",
      idle: "Badge not connected to the Hub",
      upload: "Upload",
      upload_error: "Upload error",
      error: "Error",
      offline: "Hub is offline",
      application_offline: "Application is offline",
    },
  },
  device: {
    type: {
      hub: "Hub",
      app: "Application",
    },
    status: {
      online: "Online",
      offline: "Offline",
    },
  },
};
