import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import i18n from "@/plugins/localization";
import { store } from "@/store";
import http from "@/plugins/http";
import auth from "@/plugins/auth";
import "/src/style.css";
import "@/plugins/socket";

Vue.config.productionTip = false;

if (localStorage.getItem("locale")) {
  i18n.locale = localStorage.getItem("locale");
}

new Vue({
  store,
  router,
  auth,
  http,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
