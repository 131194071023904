import Vue from "vue";
import VueNativeSock from "vue-native-websocket";
import { store } from "@/store";
import { baseUrl } from "../http";

let wsurl = new URL(baseUrl).host;
Vue.use(VueNativeSock, "//" + wsurl + "/feed", {
  store: store,
  format: "json",
  reconnection: true,
  reconnectionDelay: 3000,
  reconnectionCount: 20,
  connectManually: true,
});
