module.exports = {
  request: function (req, token) {
    let access_token = token;
    let auth_header = "Bearer " + access_token;
    if (req.url.indexOf("refresh") !== -1) {
      req.headers["Authorization"] = auth_header;
      let ref_token = localStorage.getItem("refresh_token");
      req.data = { refresh_token: ref_token };
    } else {
      req.headers["Authorization"] = auth_header;
    }
  },

  response: function (res) {
    if (res.data && res.data.data && res.data.data.refresh_token) {
      localStorage.setItem("refresh_token", res.data.data.refresh_token);
      localStorage.setItem("access_token", res.data.data.access_token);
      return res.data.data.access_token;
    }
    return null;
  },
};
