import Vue from "vue";
import VueI18n from "vue-i18n";
import russian from "@/plugins/localization/lang/russian";
import english from "@/plugins/localization/lang/english";

Vue.use(VueI18n);

export default new VueI18n({
  locale: "ru",
  fallbackLocale: "en",
  messages: {
    ru: russian,
    en: english,
  },
});
